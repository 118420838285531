/*****************  Dotted link focus outline  ************/
/**********************************************************/
/* /core/libraries/slickSlider/assets/1.4.1/slick.css */
/* /core/libraries/slickSlider/assets/1.6.1/slick.css */
.slick-list:focus {
	outline: 1px solid #0000ff;
}

/*******  Use relative than absolute unit values  *********/
/**********************************************************/
/* /core/libraries/jSignaturePad/assets/jquery.signaturepad.css */
.typed {
	font: normal 1.875em/3.125em "Journal", Georgia, Times, serif;
}

/* /core/libraries/select2/assets/select2.css */
.select2-container .select2-choice abbr {
	font-size: 0.06875rem;
}

/* /core/public/shared/assets/js/jquery/fullcalendar/3.2.0/fullcalendar.css */
.fc-time-grid-event.fc-allow-mouse-resize .fc-resizer {
	font-size: 0.6875rem;
}

/***  Foreground and background colors contrast  *****/
/**********************************************************/
/* /core/libraries/jSignaturePad/assets/jquery.signaturepad.css */
p.error {
	background-color: #ee0000;
	color: #fff;
	font-family: inherit;
}

/* /core/libraries/tablesaw/assets/2.0.3/tablesaw.bare.css */
.tablesaw-enhanced .tablesaw-bar .btn:hover,
.tablesaw-enhanced .tablesaw-bar .btn:focus {
	color: #1465a3;
	background-color: #fff;
	cursor: pointer;
}

/* /core/public/shared/assets/js/jquery/fullcalendar/3.2.0/fullcalendar.css */
.fc-basic-view .fc-day-top .fc-week-number {
	background-color: #5e5e5e;
	color: #dbdbdb;
	font-family: inherit;
}

/* core/public/shared/assets/css/responsive.css */
.pagination-wrapper .pagination-previous-page .disabled,
.pagination-wrapper .pagination-next-page .disabled {
	color: #333;
	text-decoration: line-through;
}

/**********  Removing the underline from links  ***********/
/**********************************************************/
/* /core/libraries/jSignaturePad/assets/jquery.signaturepad.css */
.sigNav a.current,
.sigNav a.current:link,
.sigNav a.current:visited {
	text-decoration: underline;
}


/* /core/public/shared/assets/js/jquery/fullcalendar/3.2.0/fullcalendar.css */
.fc-list-item-title a {
	text-decoration: underline;
}

/*  All information with color is available without color  */
/**********************************************************/
/* /core/public/shared/assets/js/jquery/fullcalendar/3.2.0/fullcalendar.css */
.fc-unthemed .fc-popover {
	background-color: #fff;
	font-weight: 700;
}

.fc-unthemed .fc-popover .fc-header .fc-close {
	color: #666;
	font-weight: 700;
}

.fc-state-hover,
.fc-state-down,
.fc-state-active,
.fc-state-disabled {
	color: #333333;
	font-weight: 700;
}

.fc-unthemed .fc-list-empty { /* theme will provide own background */
	background-color: #eee;
	font-weight: 700;
}
