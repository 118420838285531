body {
	font-family: 'Open Sans', sans-serif;
	font-size: 1rem;
	line-height: 1.25rem;
	font-weight: 400;
	min-height: 0;
}

.page-wrapper {
	color: #333;
	font-weight: 400;
}

#printHeader,
#printFooter {
	display: none;
}

h1 {
	margin-top: 0px;
	margin-bottom: 1rem;
	font-family: 'Open Sans', sans-serif;
	color: #144c8d;
	font-size: 2.25rem;
	line-height: 2.75rem;
	font-weight: 600;
}

h2 {
	margin-top: 0px;
	margin-bottom: 1rem;
	font-family: 'Open Sans', sans-serif;
	color: #144c8d;
	font-size: 2rem;
	line-height: 2.5rem;
	font-weight: 600;
}

h3 {
	margin-top: 0px;
	margin-bottom: 1rem;
	font-family: 'Open Sans', sans-serif;
	color: #144c8d;
	font-size: 1.75rem;
	line-height: 2.25rem;
	font-weight: 600;
}

h4 {
	margin-top: 0px;
	margin-bottom: 1rem;
	font-family: 'Open Sans', sans-serif;
	font-size: 1.5rem;
	line-height: 2rem;
	font-weight: 600;
}

h5 {
	margin-top: 0px;
	margin-bottom: 1rem;
	font-family: 'Open Sans', sans-serif;
	font-size: 1.25rem;
	line-height: 1.75rem;
	font-weight: 600;
}

h6 {
	margin-top: 0px;
	margin-bottom: 1rem;
	font-family: 'Open Sans', sans-serif;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 600;
}

p {
	margin-top: 0px;
	margin-bottom: 1rem;
	line-height: 1.5rem;
	font-weight: 400;
}

body a {
	color: #2196f3;
	text-decoration: none;
	border-bottom: 1px solid transparent;
}

ul {
	margin-top: 0px;
	margin-bottom: 20px;
	padding-left: 40px;
}

ol {
	margin-top: 0px;
	margin-bottom: 20px;
	padding-left: 40px;
}

strong {
	font-weight: bold;
}

blockquote {
	margin-top: 2.5rem;
	margin-bottom: 1.5rem;
	padding: 0px 1rem;
	border-left: 5px solid #e2e2e2;
	font-size: 1rem;
	line-height: 1.5rem;
}

figure {
	margin-bottom: 20px;
}

figcaption {
	margin-top: 7px;
	color: rgba(51, 51, 51, 0.56);
	font-size: 0.875rem;
	line-height: 1.25rem;
	text-align: center;
}

.about-paragraph p:last-of-type a,
.fixed-image-section .flex-vertical p:last-of-type a,
.primary,
.secondary,
.tertiary,
button[type="submit"],
.button {
	display: inline-block;
	margin-top: 1rem;
	margin-right: 1rem;
	margin-bottom: 1rem;
	padding: 0.75rem 1.5rem !important;
	border: 2px solid #012b5d !important;
	background-color: #012b5d;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #fff;
	font-size: 1rem;
	line-height: 1.25rem;
	text-align: center;
	text-decoration: none;
}

.about-paragraph p:last-of-type a:hover,
.fixed-image-section .flex-vertical p:last-of-type a:hover,
.primary:hover,
.secondary:hover,
.tertiary:hover,
button[type="submit"]:hover,
.button:hover {
	background-color: hsla(0, 0%, 100%, 0.7) !important;
	color: #012b5d;
	cursor: pointer;
}

.about-paragraph p:last-of-type a.w--current,
.fixed-image-section .flex-vertical p:last-of-type a.w--current,
.primary.w--current,
.secondary.w--current,
.tertiary.w--current,
button[type="submit"].w--current,
.button.w--current {
	background-color: #2e80b6;
	cursor: pointer;
}

.button.full-width {
	display: block;
	width: 100%;
}

.button.full-width.contact-button {
	display: block;
	width: 40%;
	margin-right: auto;
	margin-left: auto;
	border-style: none;
	border-color: transparent;
	background-color: #669a9e;
}

.button.full-width.contact-button:hover {
	background-color: #bdbdbd;
	cursor: pointer;
}

.about-paragraph p:last-of-type a,
.button.centered {
	display: inline-block;
	margin-right: auto;
	margin-left: auto;
}

.button.secondary,
.secondary,
button[type="submit"].secondary,
.tertiary,
button[type="submit"].tertiary {
	background-color: hsla(0, 0%, 100%, 0.7);
	color: #012b5d;
	cursor: pointer;
}

.button.secondary:hover,
.secondary:hover,
button[type="submit"].secondary:hover,
.tertiary:hover,
button[type="submit"].tertiary:hover {
	background-color: #012b5d !important;
	color: #fff;
	cursor: pointer;
}

.styles a.primary:not(.fc-event) {
	color: #fff;
	cursor: pointer;
}

.styles a.primary:not(.fc-event):hover {
	color: #012b5d;
	cursor: pointer;
}

.styles a.secondary:not(.fc-event),
.styles a.tertiary:not(.fc-event) {
	color: #012b5d;
	cursor: pointer;
}

.styles a.secondary:not(.fc-event):hover,
.styles a.tertiary:not(.fc-event):hover {
	color: #fff;
	cursor: pointer;
}

.styles a.primary:not(.fc-event),
.styles a.secondary:not(.fc-event),
.styles a.tertiary:not(.fc-event) {
	border-bottom: 2px solid #012b5d;
}

.styles a.primary:not(.fc-event):hover,
.styles a.secondary:not(.fc-event):hover,
.styles a.tertiary:not(.fc-event):hover {
	border-bottom: 2px solid #012b5d;
}

.navigation-link {
	padding: 1.5rem 1.3rem;
	float: none;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	border-right: 1px none hsla(0, 0%, 95%, 0.3);
	background-image: url('/20181016082423/assets/images/navy-tri.svg');
	background-position: 50% 120%;
	background-size: 12px 11px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #012b5d;
	font-size: 1rem;
	line-height: 1rem;
	text-align: center;
}

.navigation-link:hover {
	background-position: 50% 97%;
	box-shadow: inset 0 -4px 0 0 #012b5d;
}

.navigation-link.w--current {
	background-position: 50% 97%;
	background-size: 12px 11px;
	box-shadow: inset 0 -4px 0 0 #012b5d;
	color: #012b5d;
}

.navigation-link.search-dd {
	width: 3rem;
	padding-top: 2rem;
	padding-bottom: 2rem;
	float: none;
	background-image: url('/20181016082423/assets/images/navy-tri.svg'), url('/20181016082423/assets/images/search_navy.svg');
	background-position: 50% 125%, 50% 50%;
	background-size: 12px, 24px;
	background-repeat: no-repeat, no-repeat;
	cursor: pointer;
}

.navigation-link.search-dd:hover {
	background-position: 50% 97%, 50% 50%;
	background-size: 12px 11px, 24px;
}

.navigation-menu {
	position: static;
	display: flex;
	float: right;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	text-align: right;
}

.content-section {
	position: relative;
	padding-top: 3rem;
	padding-bottom: 3rem;
	background-color: #fff;
}

.content-section.news-row {
	padding-top: 3rem;
	padding-bottom: 3rem;
	background-color: rgba(20, 76, 141, 0.06);
}

.content-section.contact-section {
	padding-top: 30px;
	padding-bottom: 30px;
	background-color: #1a1d3f;
}

.content-section.gradient {
	background-image: -webkit-linear-gradient(0deg, #001c3d, #144c8d 50%, #1664c0);
	background-image: linear-gradient(90deg, #001c3d, #144c8d 50%, #1664c0);
}

.cta-heading {
	margin-top: 3px;
	margin-bottom: 0px;
	font-weight: 400;
}

.cta-heading.inline-block {
	display: inline-block;
	width: 60%;
	margin-top: 0px;
	color: #fff;
	line-height: 2.5rem;
	font-weight: 600;
}

.fixed-image-section {
	padding-top: 5rem;
	padding-bottom: 5rem;
	background-image: -webkit-linear-gradient(270deg, rgba(20, 76, 141, 0.8), rgba(20, 76, 141, 0.8)), url('/20181016082423/assets/images/131.jpg');
	background-image: linear-gradient(180deg, rgba(20, 76, 141, 0.8), rgba(20, 76, 141, 0.8)), url('/20181016082423/assets/images/131.jpg');
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
	background-attachment: scroll, fixed;
	color: #fff;
	text-align: center;
}

.section-heading {
	margin-bottom: 20px;
}

.section-heading.centered {
	color: #669a9e;
	text-align: center;
}

.form-label {
	margin-bottom: 0.5rem;
	color: #144c8d;
	font-weight: 600;
}

.section-subheading.center {
	padding-bottom: 0px;
	border-bottom: 1px none #333;
	color: #5d5d5d;
	font-size: 1.25rem;
	line-height: 1.5rem;
	text-align: center;
}

.section-title-group {
	margin-bottom: 1.5rem;
}

.form-field {
	min-height: 41px;
	margin-right: 1px;
	margin-bottom: 17px;
	border-style: none;
	border-radius: 3px;
	box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.09);
	-webkit-transition: box-shadow 150ms ease;
	transition: box-shadow 150ms ease;
}

.form-field:focus {
	box-shadow: 0 0 0 2px #2196f3;
}

.form-field.text-area {
	min-height: 110px;
}

.contact-form-wrapper {
	display: block;
	width: 50%;
	margin-right: auto;
	margin-left: auto;
}

.social-icon-link {
	padding-right: 0.75rem;
	padding-bottom: 0px;
	padding-left: 0px;
	opacity: 1;
	-webkit-transition: opacity 150ms ease;
	transition: opacity 150ms ease;
}

.social-icon-link:hover {
	opacity: 0.5;
}

.footer-d {
	padding-top: 50px;
	padding-bottom: 50px;
}

.footer-d.accent {
	padding-top: 2rem;
	padding-bottom: 2rem;
	background-color: #0f243d;
}

.grid-image {
	margin-bottom: 0.75rem;
	opacity: 1;
}

.footer-link {
	display: inline-block;
	margin-bottom: 0.5rem;
	border-bottom: 1px solid transparent;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #dbdbdb;
	text-decoration: none;
	text-transform: capitalize;
}

.footer-link:hover {
	border-bottom: 1px solid #a8a8a8;
	color: #a8a8a8;
}

.utility-page-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100vw;
	height: 100vh;
	max-height: 100%;
	max-width: 100%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.utility-page-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 260px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	text-align: center;
}

.utility-page-form {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.success-message {
	margin-bottom: 0px;
}

.error-wrapper {
	border-radius: 5px;
	background-color: #d32f2f;
	color: #fff;
}

.error-message {
	margin-bottom: 0px;
}

.container {
	position: relative;
	display: block;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
}

.container.flex-horizontal {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.container.flex-vertical {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.container.bottom-margin {
	display: block;
	margin-bottom: 1rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.container.hero-container {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
}

.container.inside-page-container {
	display: block;
	margin-top: -9rem;
	background-color: #fff;
	overflow: hidden;
	min-height: calc( 100vh - 137px - 17rem + 9rem - 291px - 79px); /* fix the height when no content available */
}

.navbar2 {
	position: absolute;
	right: 0px;
	bottom: 0px;
	background-color: transparent;
}

.signoff {
	display: block;
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
	background-color: #222;
	text-align: center;
}

.grip-subtext {
	display: block;
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	color: #fff;
	font-size: 0.5rem;
	line-height: 0.5rem;
	text-align: right;
}

.footer-text {
	margin-bottom: 1rem;
	color: #dbdbdb;
	font-weight: 400;
	text-align: left;
}

.news-summary {
	display: block;
	line-height: 1.5rem;
}

.footer-heading {
	margin-bottom: 1rem;
	font-family: Roboto, sans-serif;
	color: #fff;
	font-weight: 500;
	text-align: left;
	text-transform: uppercase;
}

.header-section {
	position: relative;
	z-index: 3;
	background-color: #d6e1e7;
	box-shadow: 0 1px 5px 0 #5d5d5d;
}

.logo.w--current {
	display: inline-block;
	width: 5rem;
	height: 5rem;
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.logo-home {
	position: static;
	left: 215px;
	top: 0px;
	z-index: 3;
	width: 170px;
	margin-top: 2.1rem;
	margin-left: 1rem;
	padding: 0.75rem 0.25rem 18px 0;
}

.logo-home img {
	height: 100%;
	width: 100%;
}

.feature-div {
	display: block;
	width: 46%;
	margin: 0.75rem;
	padding: 1rem;
	background-color: rgba(20, 76, 141, 0.05);
	box-shadow: 0 0 5px 2px #e6e6e6;
	text-align: center;
}

.two-thirds {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 66%;
	float: left;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-align-content: stretch;
	-ms-flex-line-pack: stretch;
	align-content: stretch;
}

.column-25-percent {
	display: inline-block;
	width: 25%;
	height: auto;
	padding-right: 1rem;
	padding-left: 1rem;
}

.column-25-percent.right-align {
	text-align: right;
}

.top-navigation {
	float: right;
}

.phone-link {
	display: block;
	margin-bottom: 1rem;
	color: #dbdbdb;
	text-align: left;
}

.email-link {
	margin-bottom: 1.5rem;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #dbdbdb;
	text-align: left;
	text-transform: none;
}

.email-link:hover {
	color: #a8a8a8;
	cursor: pointer;
}

#GRIPFooterLogo {
	margin: 0 auto;
}

#GRIPFooterLogoText {
	line-height: 0.5rem;
}

.copyright-text {
	display: inline-block;
	color: #dbdbdb;
	font-size: 0.875rem;
	line-height: 1.125rem;
	text-align: right;
}

.inside-body-section {
	background-color: #dce4ee;
	cursor: default;
	margin-top: 137px;
}

.nav-drop-wrap {
	position: relative;
	float: none;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	border-right: 1px none hsla(0, 0%, 95%, 0.3);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.nav-drop-toggle {
	position: relative;
	width: 100%;
	padding: 0px;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: hsla(0, 0%, 65%, 0.5);
}

.nav-drop-toggle:hover {
	color: hsla(0, 0%, 65%, 0.8);
	cursor: pointer;
}

.nav-menu-wrap {
	margin-bottom: 0;
	padding-left: 0;
	list-style-type: none;
}

.nav-drop-toggle-link {
	display: inline-block;
	width: 100%;
	padding: 1.5rem 1.3rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-right: 0px none transparent;
	background-image: url('/20181016082423/assets/images/navy-tri.svg');
	background-position: 50% 118%;
	background-size: 12px 11px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #012b5d;
	line-height: 1rem;
	text-align: center;
	text-transform: capitalize;
	border-bottom: 0 none;
}

.nav-drop-wrap:hover .nav-drop-toggle-link {
	background-position: 50% 97%;
	box-shadow: inset 0 -4px 0 0 #012b5d;
}

.nav-drop-wrap:hover .nav-drop-toggle-link.active-item,
.nav-drop-toggle-link.active-item {
	background-position: 50% 100%;
	box-shadow: inset 0 -3px 0 0 #012b5d;
}

.nav-drop-icon {
	margin-right: 1.5rem;
	font-size: 0.75rem;
}

.nav-drop-list {
	background-color: #dbdbdb;
	display: none;
}

.open .nav-drop-list {
	display: block;
	padding-bottom: 0.5rem;
	background-color: #d4d4d4;
	min-width: 185px;
}

.nav-drop-list-inside-wrap {
	padding-left: 0;
	margin-bottom: 0;
	list-style-type: none;
}

.nav-drop-list a,
.nav-drop-list-link {
	display: block;
	margin-top: 2px;
	margin-bottom: 2px;
	padding: 0.5rem 1.5rem 0.5rem 0.5rem;
	background-color: transparent;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #012b5d;
	font-size: 0.9rem;
}

.nav-drop-list a:hover,
.nav-drop-list-link:hover {
	background-color: #fff;
	cursor: pointer;
}

.nav-drop-list a.w--current,
.nav-drop-list-link.w--current {
	background-color: #fff;
	color: #012b5d;
	cursor: pointer;
}

.main-content-wrap.with-left-sidebar {
	width: 74%;
	padding-top: 2rem;
	padding-right: 1.5rem;
	padding-bottom: 2rem;
	float: right;
}

.main-content-wrap.with-right-sidebar {
	width: 74%;
	padding-top: 2rem;
	padding-bottom: 2rem;
	padding-left: 2rem;
	float: left;
}

.main-content-wrap.full-width {
	display: block;
	width: 85%;
	margin-right: auto;
	margin-left: auto;
	padding-top: 2rem;
	padding-right: 1.5rem;
	padding-bottom: 2rem;
}

.main-content-wrap.both-sidebars {
	display: inline-block;
	width: 54%;
	margin-left: 1.5rem;
	padding-top: 2rem;
	padding-right: 1rem;
	padding-bottom: 2rem;
}

.section-div {
	padding-top: 4rem;
	padding-bottom: 4rem;
}

.styles {
	display: block;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
}

.h1-link {
	display: inline;
	margin-bottom: 1rem;
	border-bottom: 1px dotted #a8a8a8;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #144c8d;
	font-size: 2.25rem;
	line-height: 2.75rem;
	font-weight: 600;
}

.h1-link:hover {
	border-bottom-style: dotted;
	border-bottom-color: transparent;
	color: #5d5d5d;
}

.top-nav {
	position: relative;
	height: auto;
	background-color: #012b5d;
}

.top-nav-link {
	display: inline-block;
	padding: 0.5rem 0.75rem;
	border-bottom: 1px none transparent;
	border-left: 1px solid hsla(0, 0%, 86%, 0.2);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #f1f1f1;
	font-size: 0.85rem;
	line-height: 1rem;
	text-decoration: none;
}

.top-nav-link:hover {
	background-color: hsla(0, 0%, 100%, 0.9);
	color: #012b5d;
	cursor: pointer;
}

.top-nav-link.double-border {
	border-right: 1px solid hsla(0, 0%, 86%, 0.2);
	background-color: #067c39;
	color: #f1f1f1;
}

.top-nav-link.double-border:hover {
	background-color: hsla(0, 0%, 100%, 0.9);
	color: #067c39;
	cursor: pointer;
}

.top-nav-search {
	display: inline-block;
	width: 3rem;
	float: right;
}

.header-search-container {
	position: absolute;
	left: 0px;
	top: 100%;
	right: 0px;
	display: none;
	overflow: hidden;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
}

.search-form-wrap {
	position: relative;
	z-index: 100;
	width: 380px;
	padding-top: 1rem;
	padding-right: 1rem;
	padding-left: 1rem;
	float: right;
	background-color: #dbdbdb;
}

.search-form {
	height: 2.5rem;
}

.search-field-d {
	width: 75%;
	height: 2.5rem;
	float: left;
	border-radius: 0;
}

.search-link {
	width: 25%;
	height: 2.5rem;
	float: right;
	background-color: #012b5d;
}

.news-div {
	display: inline-block;
	width: 30%;
	padding: 1rem 1rem 1.5rem;
	background-color: #fff;
}

.news-div.right-float {
	margin-right: 2%;
	float: right;
}

.news-div.center {
	margin-right: 3%;
	margin-left: 3%;
}

.news-div.left-float {
	margin-left: 2%;
	float: left;
}

.feature-description {
	display: block;
	width: 80%;
	margin-right: auto;
	margin-left: auto;
}

.news-heading {
	display: block;
	max-width: 400px;
	margin-right: auto;
	margin-bottom: 2rem;
	margin-left: auto;
	color: #012b5d;
	text-align: center;
}

.news-div-heading {
	margin-bottom: 1rem;
	color: #144c8d;
	font-size: 1.5rem;
	line-height: 2rem;
	font-weight: 600;
	text-align: left;
}

.news-link {
	display: inline-block;
	border-bottom: 1px solid rgba(20, 76, 141, 0.3);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #144c8d;
	line-height: 1.5rem;
	text-transform: capitalize;
}

.news-link:hover {
	border-bottom-color: rgba(20, 76, 141, 0.8);
	color: #144c8d;
}

.feature-link {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 1px solid transparent;
	color: #144c8d;
	font-size: 1.65rem;
	line-height: 2rem;
	font-weight: 600;
}

.feature-link:hover {
	border-bottom: 1px solid #012b5d;
	color: #012b5d;
}

.about-header {
	margin-bottom: 1.5rem;
	color: #012b5d;
	font-size: 2rem;
	line-height: 2.5rem;
	font-weight: 600;
	text-align: center;
}

.about-paragraph p {
	margin-bottom: 0.5rem;
	text-align: center;
}

.about-paragraph p:last-of-type,
.button-wrap {
	text-align: center;
	margin-bottom: 0;
}

.one-third {
	display: inline-block;
	width: 33%;
	padding: 0.75rem 1rem 1rem 1.75rem;
	float: none;
	background-color: hsla(0, 0%, 100%, 0.8);
}

.one-third p {
	margin-bottom: 0;
}

.one-third p a,
.quick-link {
	display: block;
	margin-bottom: 1.25rem;
	padding: 0.35rem 2.5rem 0.35rem 1rem;
	border-left: 4px solid #447dc0;
	background-color: #012b5d;
	background-image: url('/20181016082423/assets/images/arrow-circle-medium.svg');
	background-position: 96% 50%;
	background-size: 20px 20px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #fff;
	font-size: 1.25rem;
	line-height: 2rem;
	font-weight: 400;
	text-decoration: none;
}

.one-third p a:hover,
.quick-link:hover {
	background-color: #144c8d;
	cursor: pointer;
}

.news-image {
	width: 100%;
	height: 150px;
	margin-bottom: 0.75rem;
	background-image: url('/20181016082423/assets/images/example-bg.png');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.footer-link-wrap {
	text-align: left;
}

.ncua-logo {
	display: inline-block;
	width: 80px;
	height: 50px;
	margin-right: auto;
	margin-left: auto;
	background-image: url('/20181016082423/assets/images/logo-ncua.png');
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
	text-align: center;
}

.ehl-logo {
	display: inline-block;
	width: 80px;
	height: 50px;
	background-image: url('/20181016082423/assets/images/Equalhousinglender.svg');
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
}

.about-link {
	display: inline;
	margin-bottom: 0.5rem;
	border-bottom: 1px dotted hsla(0, 0%, 86%, 0.8);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #dbdbdb;
	font-size: 0.875rem;
	text-decoration: none;
}

.about-link:hover {
	border-bottom-color: transparent;
	color: #a8a8a8;
}

.about-link-wrap {
	text-align: right;
}

.about-link-wrap.last-link-wrap {
	margin-bottom: 1rem;
}

.about-us-section {
	display: block;
	width: 96%;
	margin-right: auto;
	margin-left: auto;
	padding: 2rem 9rem;
	background-color: #fff;
	margin-top: 137px;
}

.slider {
	height: 500px;
	max-height: 600px;
	min-height: 325px;
	margin-top: 137px;
}

.slide {
	position: relative;
}

.slide-image-wrap {
	width: 100%;
	height: 100%;
}

.slide-image-wrap {
	position: relative;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.slide-caption {
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: 0px;
	display: block;
	overflow: hidden;
	width: 1200px;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
	padding: 2rem 5rem 2.5rem 2rem;
	float: none;
	background-color: hsla(0, 0%, 100%, 0.6);
}

.slider-header {
	display: block;
	margin-bottom: 1rem;
	color: #012b5d;
	font-size: 2.35rem;
	line-height: 2.75rem;
	font-weight: 600;
}

.slider-subheader p {
	display: block;
	margin-bottom: 0.5rem;
	color: #012b5d;
	font-size: 1.2rem;
	line-height: 1.5rem;
	font-weight: 600;
}

.arrow-icon {
	width: 50px;
	height: 50px;
	padding: 6px;
	color: #5d5d5d;
	font-size: 2rem;
}

.slider-navigation {
	width: 70%;
	color: #333;
	font-size: 0.875rem;
}

.h2-link {
	display: inline;
	margin-bottom: 1rem;
	border-bottom: 1px dotted #a8a8a8;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #144c8d;
	font-size: 2rem;
	line-height: 2.5rem;
	font-weight: 600;
}

.h2-link:hover {
	border-bottom-color: transparent;
	color: #5d5d5d;
}

.h3-link {
	display: inline;
	margin-bottom: 1rem;
	border-bottom: 1px dotted #a8a8a8;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #144c8d;
	font-size: 1.75rem;
	line-height: 2.25rem;
	font-weight: 600;
}

.h3-link:hover {
	border-bottom-color: transparent;
	color: #5d5d5d;
}

.h4-link {
	display: inline;
	margin-bottom: 1rem;
	border-bottom: 1px dotted #a8a8a8;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #144c8d;
	font-size: 1.5rem;
	line-height: 2rem;
	font-weight: 600;
}

.h4-link:hover {
	border-bottom-color: transparent;
	color: #5d5d5d;
}

.h5-link {
	display: inline;
	margin-bottom: 1rem;
	padding-left: 1px;
	border-bottom: 1px dotted #a8a8a8;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #144c8d;
	font-size: 1.25rem;
	line-height: 1.75rem;
	font-weight: 600;
}

.h5-link:hover {
	border-bottom-color: transparent;
	color: #5d5d5d;
}

.h6-link {
	display: inline;
	margin-bottom: 1rem;
	border-bottom: 1px dotted #a8a8a8;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #144c8d;
	line-height: 1.5rem;
	font-weight: 600;
}

.h6-link:hover {
	border-bottom-color: transparent;
	color: #5d5d5d;
}

.intro_paragraph,
.intro-paragraph {
	font-size: 1.25rem;
	line-height: 1.75rem;
}

.right-sidebar .box a,
.left-sidebar .box a,
.styles a:not(.fc-event),
.text-link {
	border-bottom: 1px dotted #a8a8a8;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #144c8d;
}

.right-sidebar .box a:hover,
.left-sidebar .box a:hover,
.styles a:hover,
.text-link:hover {
	border-bottom-color: transparent;
	color: #5d5d5d;
}

.styles ol,
.styles ul,
.ordered-list,
.unordered-list {
	margin-bottom: 1rem;
}

.styles li,
.list-item {
	margin-bottom: 0.35rem;
}

.styles ol ul,
.styles ul ol,
.styles ul ul,
.styles ol ol {
	margin-bottom: 10px;
	margin-top: 10px;
}

blockquote,
.block-quote {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1.25rem;
	border-left-color: #144c8d;
	font-size: 1.125rem;
	line-height: 1.5rem;
}

.table-styling {
	margin-bottom: 1rem;
}

.text-field {
	margin-bottom: 0.5rem;
}

.screen-message {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
}

.screen-message.success {
	background-color: #adebb0;
	display: block;
}

.screen-message.error {
	background-color: rgba(189, 7, 26, 0.15);
	display: block;
}

.screen-message-text {
	font-size: 1.125rem;
	line-height: 1.5rem;
}

.breadcrumbs {
	margin-bottom: 1.5rem;
}

.breadcrumbs-list {
	margin-bottom: 1rem;
	padding-left: 0px;
}

.breadcrumbs-list-item {
	display: inline-block;
}

.breadcrumb-link {
	display: inline-block;
	margin-right: 0.45rem;
	border-bottom: 1px dotted #7a7a7a;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #747474;
	font-size: 0.75rem;
}

.breadcrumb-link:hover {
	border-bottom-color: transparent;
	color: #333;
}

.breadcrumb-link.w--current {
	border-bottom-color: transparent;
	color: #333;
}

.breadcrumb-divider {
	display: inline-block;
	margin-right: 0.45rem;
	color: #747474;
	font-size: 0.75rem;
}

.inside-header-image {
	position: relative;
	height: 17rem;
	background-image: -webkit-linear-gradient(270deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), url('/20181016082423/assets/images/banner.jpg');
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), url('/20181016082423/assets/images/banner.jpg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.slide-3 {
	position: relative;
}

.left-navigation {
	display: inline-block;
	width: 21%;
	margin-top: 2rem;
	margin-left: 1.5rem;
	float: left;
}

.left-navigation nav {
	border-top: 5px solid #144c8d;
	background-color: #edeae9;
	padding-bottom: 1rem;
}

.mobile-search-link {
	display: inline-block;
}

.left-nav-list-item {
	margin-right: 1rem;
	margin-left: 1rem;
}

.left-nav-list-item.no-right-margin {
	margin-right: 0rem;
}

.left-nav-list-item.half-margin-width {
	margin-right: 0.5rem;
	margin-left: 0.5rem;
}

.left-nav-section-title-link {
	display: block;
	padding: 1.25rem 1rem;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #144c8d;
	font-size: 1.25rem;
	line-height: 1.75rem;
	font-weight: 600;
	text-transform: uppercase;
}

.left-nav-section-title-link:hover {
	background-color: rgba(20, 76, 141, 0.06);
	cursor: pointer;
}

.left-nav-link-level-1 {
	display: block;
	padding: 0.5rem;
	border-bottom: 1px dotted #aaa;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #333;
}

.left-nav-link-level-1.w--current,
.left-nav-link-level-1:hover,
.left-nav-link-level-1.selected {
	background-color: #d7dadf;
	cursor: pointer;
}

.nested-unordered-list {
	margin-bottom: 0px;
}

.left-nav-link-level-2 {
	display: block;
	padding: 0.5rem;
	border-bottom: 1px dotted #aaa;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #333;
	font-size: 0.875rem;
}

.left-nav-link-level-2.w--current,
.left-nav-link-level-2:hover,
.left-nav-link-level-2.selected {
	background-color: rgba(20, 76, 141, 0.1);
	cursor: pointer;
}

.left-nav-link-level-3 {
	display: block;
	padding: 0.5rem;
	border-bottom: 1px dotted #aaa;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #333;
	font-size: 0.8rem;
}

.left-nav-link-level-3:hover {
	background-color: rgba(20, 76, 141, 0.1);
	cursor: pointer;
}

.left-nav-link-level-3.w--current,
.left-nav-link-level-3.selected {
	background-color: rgba(20, 76, 141, 0.1);
	cursor: pointer;
}

.right-sidebar {
	width: 16%;
	min-width: 171px;
	margin-top: 5rem;
	margin-right: 1.5rem;
	float: right;
}

.right-sidebar .box,
.left-sidebar .box,
.right-sidebar-item-wrap {
	margin-bottom: 1rem;
	padding-top: 1rem;
	border-top: 1px solid #dbdbdb;
}

.right-sidebar .box h2,
.right-sidebar .box h3,
.right-sidebar .box h4,
.right-sidebar .box h5,
.left-sidebar .box h2,
.left-sidebar .box h3,
.left-sidebar .box h4,
.left-sidebar .box h5,
.right-sidebar-heading {
	margin-bottom: 0.75rem;
	color: #144c8d;
	font-size: 1.125rem;
	line-height: 1.25rem;
	font-weight: 600;
}

.right-sidebar .box td,
.right-sidebar .box p,
.left-sidebar .box td,
.left-sidebar .box p,
.right-sidebar-paragraph {
	color: #838383;
	font-size: 0.875rem;
	line-height: 1.35rem;
}

.left-sidebar .box a,
.right-sidebar .box a {
	font-size: 0.875rem;
	line-height: 1.35rem;
}

.navigation-wrap {
	position: absolute;
	right: 0px;
	bottom: 0px;
	width: 80%;
	height: 100%;
}

.link-wrap {
	margin-bottom: 1rem;
}

.image-for-test {
	width: 50%;
	height: 400px;
	background-image: url('/20181016082423/assets/images/example-bg.png');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

html.w-mod-js *[data-ix="fade-in-bottom-page-loads"] {
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="fade-in-left-scroll-in"] {
	opacity: 0;
	-webkit-transform: translate(-50px, 0px);
	-ms-transform: translate(-50px, 0px);
	transform: translate(-50px, 0px);
}

html.w-mod-js *[data-ix="fade-in-right-scroll-in"] {
	opacity: 0;
	-webkit-transform: translate(50px, 0px);
	-ms-transform: translate(50px, 0px);
	transform: translate(50px, 0px);
}

html.w-mod-js *[data-ix="fade-in-top-scroll-in"] {
	opacity: 0;
	-webkit-transform: translate(0px, -50px);
	-ms-transform: translate(0px, -50px);
	transform: translate(0px, -50px);
}

html.w-mod-js *[data-ix="fade-in-bottom-scroll-in"] {
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="bounce-in-scroll-in"] {
	opacity: 0;
	-webkit-transform: scale(0.6000000000000006, 0.6000000000000006);
	-ms-transform: scale(0.6000000000000006, 0.6000000000000006);
	transform: scale(0.6000000000000006, 0.6000000000000006);
}

html.w-mod-js *[data-ix="scale-on-scroll"] {
	opacity: 0;
	-webkit-transform: scale(0.01, 0.01);
	-ms-transform: scale(0.01, 0.01);
	transform: scale(0.01, 0.01);
}

.footer-phone-link {
	display: none;
}

@media (max-width: 991px) {
	.footer-phone-text {
		display: none;
	}

	.footer-phone-link {
		display: inline-block;
	}

	.navigation-link {
		width: 100%;
		border-bottom: 1px solid #fff;
		background-image: none;
		background-size: auto;
		background-repeat: repeat;
		color: #fff;
		text-align: left;
	}

	.navigation-link:hover {
		background-color: #012b5d;
		box-shadow: none;
	}

	.navigation-link.w--current {
		border-right-color: transparent;
		border-bottom-style: solid;
		border-bottom-color: #fff;
		background-color: #012b5d;
		box-shadow: inset 0 0 8px 0 #012b5d;
		color: #fff;
	}

	.navigation-menu {
		position: fixed;
		top: 4.5rem;
		right: 0px;
		display: block;
		width: 90%;
		height: auto;
		margin-top: 4.5rem;
		margin-left: 10%;
		float: right;
		background-color: #144c8d;
	}

	.content-section {
		padding-top: 2.5rem;
		padding-bottom: 2.5rem;
	}

	.content-section.news-row {
		padding-top: 2.5rem;
		padding-bottom: 2.5rem;
	}

	.cta-heading.inline-block {
		width: 60%;
		margin-top: 0px;
		font-size: 1.8rem;
	}

	.fixed-image-section {
		padding-top: 4.5rem;
		padding-bottom: 4.5rem;
	}

	.section-title-group {
		margin-bottom: 40px;
	}

	.footer-d.accent {
		margin-right: 0px;
		padding-right: 0px;
		padding-left: 0px;
	}

	.container {
		width: 100%;
	}

	.navbar2 {
		width: 100%;
	}

	.feature-div {
		width: 44%;
	}

	.nav-drop-wrap {
		width: 100%;
		margin-left: 0px;
	}

	.nav-drop-toggle-link {
		border-bottom: 1px solid #fff;
		background-image: none;
		background-size: auto;
		background-repeat: repeat;
		color: #fff;
		text-align: left;
	}

	.nav-drop-toggle-link:hover {
		background-color: #012b5d;
		box-shadow: inset 0 0 0 0 transparent;
	}

	.nav-drop-toggle-link.w--current {
		background-color: #012b5d;
		box-shadow: inset 0 0 8px 0 transparent;
		color: #fff;
	}

	.nav-drop-toggle-link.w--current:hover {
		box-shadow: none;
	}

	.main-content-wrap.with-left-sidebar {
		display: block;
		width: 90%;
		margin-right: auto;
		margin-left: auto;
		float: none;
	}

	.main-content-wrap.with-right-sidebar {
		display: block;
		width: 90%;
		margin-right: auto;
		margin-left: auto;
		padding-right: 1.5rem;
		padding-left: 0rem;
		float: none;
	}

	.main-content-wrap.full-width {
		width: 90%;
	}

	.main-content-wrap.both-sidebars {
		display: block;
		width: 90%;
		margin-right: auto;
		margin-left: auto;
		padding-right: 1.5rem;
	}

	.header-search-container {
		display: none;
	}

	.one-third {
		padding-left: 1rem;
	}

	.one-third p a,
	.quick-link {
		font-size: 1.125rem;
	}

	.news-image {
		background-position: 50% 50%;
		background-size: cover;
		background-repeat: no-repeat;
	}

	.about-us-section {
		padding-right: 6rem;
		padding-left: 6rem;
	}

	.menu-button {
		position: static;
		top: 0px;
		right: 0px;
		display: block;
		width: 4rem;
		height: 4.5rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.mm-wrapper_blocking .menu-button {
		background-color: rgba(20, 76, 141, 0.5);
		cursor: pointer;
	}

	.menu-icon {
		position: static;
		left: 0px;
		bottom: 23px;
		color: #012b5d;
		font-size: 2rem;
		line-height: 2rem;
		text-align: center;
	}

	.slider {
		height: 450px;
	}

	.slide-image-wrap {
		height: 450px;
	}

	.slide-caption {
		position: absolute;
		width: 100%;
		padding: 1rem 3rem 2.5rem 4rem;
	}

	.slider-header {
		margin-bottom: 0.5rem;
		font-size: 1.85rem;
		line-height: 2.5rem;
	}

	.slider-arrow-link.left {
		z-index: 1;
		margin-left: 0px;
	}

	.slider-arrow-link.right {
		z-index: 1;
		margin-right: 0px;
	}

	.arrow-icon.left {
		margin-bottom: 0px;
		margin-left: 0px;
		font-size: 1.5rem;
	}

	.arrow-icon.right {
		margin-right: 0px;
		margin-bottom: 0px;
		font-size: 1.5rem;
	}

	.slider-navigation {
		font-size: 0.75rem;
	}

	.mobile-search-link {
		position: static;
		top: 0px;
		right: 4.25rem;
		width: 4rem;
		height: 4.5rem;
		float: right;
		background-image: url('/20181016082423/assets/images/search_navy.svg');
		background-position: 50% 50%;
		background-size: 24px;
		background-repeat: no-repeat;
		-webkit-transition: all 200ms ease;
		transition: all 200ms ease;
		cursor: pointer;
	}

	.right-sidebar {
		display: block;
		width: 90%;
		margin: 1rem auto 2rem;
		float: none;
	}

	.inside-header-image {
		background-image: -webkit-linear-gradient(270deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), url('/20181016082423/assets/images/banner-tablet.jpg');
		background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), url('/20181016082423/assets/images/banner-tablet.jpg');
	}
}

@media (max-width: 767px) {
	.about-paragraph p:last-of-type a,
	.fixed-image-section .flex-vertical p:last-of-type a,
	.primary,
	.secondary,
	.tertiary,
	button[type="submit"],
	.button {
		padding: 0.5rem 0.75rem !important;
	}

	.content-section.contact-section {
		padding-bottom: 20px;
	}

	.cta-heading {
		margin-bottom: 10px;
	}

	.cta-heading.inline-block {
		width: 70%;
		margin-bottom: 0px;
		font-size: 1.25rem;
		line-height: 1.675rem;
	}

	.fixed-image-section {
		padding-top: 2.5rem;
		padding-bottom: 2.5rem;
	}

	.section-heading.centered {
		margin-bottom: 16px;
		font-size: 1.875rem;
	}

	.section-subheading.center {
		font-size: 1.125rem;
	}

	.section-title-group {
		margin-bottom: 35px;
	}

	.footer-d {
		padding-right: 20px;
		padding-left: 20px;
	}

	.footer-d.accent {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-top: 1.75rem;
		padding-bottom: 1.5rem;
	}

	.footer-link {
		font-size: 0.875rem;
		line-height: 1.125rem;
	}

	.container.flex-horizontal {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.container.bottom-margin {
		margin-bottom: 0.25rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.container.mobile-flex {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: reverse;
		-webkit-flex-direction: column-reverse;
		-ms-flex-direction: column-reverse;
		flex-direction: column-reverse;
	}

	.footer-text {
		font-size: 0.875rem;
		line-height: 1rem;
	}

	.feature-div {
		width: 44%;
	}

	.two-thirds {
		width: 100%;
	}

	.column-25-percent {
		width: 33%;
	}

	.column-25-percent.right-align {
		width: 100%;
		margin-top: 1.25rem;
		padding-top: 1rem;
		border-top: 1px solid hsla(0, 0%, 66%, 0.5);
		text-align: left;
	}

	.copyright-text {
		display: inline-block;
		margin-right: 0.5rem;
		text-align: left;
	}

	.top-nav-link {
		background-color: #012b5d;
		cursor: pointer;
	}

	.header-search-container {
		display: none;
	}

	.news-div {
		width: 94%;
		margin-bottom: 1rem;
	}

	.news-div.right-float {
		display: block;
		margin-right: auto;
		margin-left: auto;
		float: none;
	}

	.news-div.center {
		display: block;
		margin-right: auto;
		margin-left: auto;
	}

	.news-div.left-float {
		display: block;
		margin-right: auto;
		margin-left: auto;
		float: none;
	}

	.one-third {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		margin-right: auto;
		margin-left: auto;
		padding-top: 1rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.one-third p a,
	.quick-link {
		width: 30%;
		min-width: 200px;
		margin-right: 0.6rem;
		margin-left: 0.6rem;
		padding-right: 2.25rem;
		font-size: 1rem;
	}

	.about-link-wrap {
		display: inline-block;
		margin-right: 1rem;
		text-align: left;
	}

	.about-us-section {
		width: 94%;
		padding-right: 4rem;
		padding-left: 4rem;
	}

	.slider {
		height: auto;
		background-color: rgba(20, 76, 141, 0.06);
	}

	.slide {
		background-color: transparent;
		width: 100%;
	}

	.slide-image-wrap {
		height: 250px;
	}

	.slide-caption {
		position: static;
		width: 100%;
		padding-bottom: 2.75rem;
		padding-left: 3.5rem;
		background-color: transparent;
	}

	.slider-header {
		font-size: 1.75rem;
		line-height: 2.125rem;
	}

	.slider-subheader p {
		font-size: 1.125rem;
		line-height: 1.35rem;
	}

	.mask {
		background-color: transparent;
		width: 100%;
	}

	.ordered-list {
		padding-left: 20px;
	}

	.unordered-list {
		padding-left: 20px;
	}

	.navigation-wrap {
		width: 75%;
	}

	.inside-header-image {
		background-image: -webkit-linear-gradient(270deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), url('/20181016082423/assets/images/banner-mobile.jpg');
		background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), url('/20181016082423/assets/images/banner-mobile.jpg');
	}

	.logo-home {
		width: 160px;
	}
}

@media (max-width: 479px) {
	.about-paragraph p:last-of-type a,
	.fixed-image-section .flex-vertical p:last-of-type a,
	.primary,
	.secondary,
	.tertiary,
	button[type="submit"],
	.button {
		margin-bottom: 0.5rem;
	}

	.navigation-menu {
		margin-top: 4.5rem;
	}

	.content-section {
		padding-top: 1.5rem;
	}

	.content-section.news-row {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	.content-section.gradient {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	.cta-heading.inline-block {
		width: 80%;
		font-size: 1.25rem;
		line-height: 1.75rem;
	}

	.section-title-group {
		margin-bottom: 25px;
	}

	.footer-d.accent {
		padding-top: 1.5rem;
		padding-bottom: 1rem;
	}

	.footer-link {
		text-align: center;
	}

	.container.flex-horizontal {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.container.navbar-container {
		height: 7.5rem;
		background-color: #fff;
	}

	.footer-text {
		text-align: center;
	}

	.news-summary {
		font-size: 0.9375rem;
		line-height: 1.125rem;
	}

	.footer-heading {
		margin-bottom: 0.75rem;
		text-align: center;
	}

	.feature-div {
		display: block;
		width: 85%;
		margin-right: auto;
		margin-left: auto;
	}

	.two-thirds {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.column-25-percent {
		width: 80%;
		margin-top: 0.5rem;
		margin-bottom: 1.5rem;
		text-align: center;
	}

	.column-25-percent.right-align {
		margin-top: 0rem;
		margin-bottom: 0.5rem;
	}

	.phone-link {
		text-align: center;
	}

	.email-link {
		text-align: center;
	}

	.grip-footer-logo {
		display: block;
		margin-right: auto;
		margin-left: auto;
		float: none;
	}

	.copyright-text {
		text-align: center;
	}

	.header-search-container {
		display: none;
		width: 100%;
	}

	.search-form-wrap {
		width: 100%;
		background-color: hsla(0, 0%, 86%, 0.9);
	}

	.search-link {
		display: block;
		font-size: 0.875rem;
	}

	.news-div {
		width: 90%;
	}

	.feature-link {
		font-size: 1.35rem;
	}

	.footer-link-wrap {
		text-align: center;
	}

	.about-link-wrap {
		display: block;
		text-align: center;
		margin-right: 0;
	}

	.about-us-section {
		width: 85%;
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.menu-button {
		top: auto;
		right: 0px;
		bottom: 0px;
		display: inline-block;
	}

	.menu-button.w--open {
		border-bottom-style: none;
		border-bottom-color: transparent;
	}

	.slider {
		background-color: rgba(20, 76, 141, 0.06);
		clear: both;
	}

	.slide-image-wrap {
		height: 180px;
	}

	.slide-caption {
		padding-right: 2rem;
		padding-left: 2.5rem;
		background-color: transparent;
	}

	.slider-header {
		font-size: 1.65rem;
		line-height: 2rem;
	}

	.slider-subheader p {
		font-size: 1rem;
	}

	.slider-navigation {
		font-size: 0.625rem;
	}

	.mobile-search-link {
		left: 0rem;
		top: 4.25rem;
		right: auto;
		z-index: 6;
		width: 3.5rem;
	}

	.mobile-blue-div {
		position: absolute;
		left: 0px;
		right: 0px;
		bottom: 0px;
		height: 3.25rem;
		background-color: #144c8d;
	}

	.copyright-text-wrap {
		text-align: center;
	}

	.navigation-wrap {
		width: 50%;
	}

	.inside-header-image {
		background-image: -webkit-linear-gradient(270deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), url('/20181016082423/assets/images/banner-mobile-vertical.jpg');
		background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), url('/20181016082423/assets/images/banner-mobile-vertical.jpg');
	}

	.logo-home {
		width: 150px;
	}
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}

body a[href^=tel] {
	color: #dbdbdb;
	cursor: pointer;
}

::-moz-selection {
	background-color: #000000;
	color: #ffffff;
	opacity: 1;
}

::selection {
	background-color: #000000;
	color: #ffffff;
	opacity: 1;
}

@media screen and (max-width: 850px) {
	.container.mobile-flex {
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: reverse;
		flex-direction: column-reverse;
	}

	.two-thirds {
		width: 100%;
	}

	.one-third {
		display: flex;
		-webkit-box-pack: center;
		justify-content: center;
		flex-wrap: wrap;
		width: 100%;
		margin-right: auto;
		margin-left: auto;
		padding-top: 2.25rem;
		padding-bottom: 1rem;
		background-color: #cad7e8;
		margin-bottom: 2rem;
	}

	.one-third p a,
	.quick-link {
		width: 30%;
		min-width: 200px;
		margin-right: 0.6rem;
		margin-left: 0.6rem;
		padding-right: 2.25rem;
		font-size: 1rem;
	}

	.section.features-row {
		padding-top: 0;
	}
}

@media screen and (max-width: 470px) {
	.one-third {
		background-color: #fff;
		margin-bottom: 0;
	}

	.one-third p a,
	.quick-link {
		width: 100%;
	}
}

/* skip button */
.sronly.skip-button {
	height: 0px;
	padding: 0px !important;
	width: 200px;
}

.sronly.skip-button:focus {
	height: auto;
	z-index: 200;
	padding: 9px 16px !important;
}

/* header */
/* sticky header */
.header2 {
	box-shadow: inset 0 32px 0 0 #012b5d;
	background-color: #fff;
	position: fixed;
	top: 0px;
	width: 100%;
	z-index: 5;
}

/* do we need media query? */
@media (max-width: 991px) {
	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
		max-width: none;
	}
}

/*mmenu*/
.mobile-navigation-menu li.active .nav-link {
	background-color: #012b5d !important;
}

@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened ~ .mm-slideout {
		-webkit-transform: translate(0, 0);
		-ms-transform: translate(0, 0);
		transform: translate(0, 0);
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@media (max-width: 991px) {
	.navigation-menu {
		top: 0;
		margin-top: 0;
		width: 80%;
	}

	.mobile-navigation-menu,
	.desktop-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
	}

	.mm-wrapper_opening .mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
		-webkit-transform: translate(-80%, 0);
		-ms-transform: translate(-80%, 0);
		transform: translate(-80%, 0);
		-webkit-transform: translate3d(-80%, 0, 0);
		transform: translate3d(-80%, 0, 0);
	}

	.mm-menu_offcanvas {
		max-width: none;
	}

	.mm-panel {
		padding: 0;
	}

	.mm-panels > .mm-panel > .mm-listview {
		margin: -20px 0 20px;
	}

	.mm-panels > .mm-panel + .mm-panel > .mm-listview {
		margin-top: 41px;
	}

	.mm-menu a,
	.mm-menu a:active,
	.mm-menu a:link,
	.mm-menu a:visited {
		color: #fff;
		padding: 1.25em 1.375rem;
		border-bottom: 1px solid #fff;
		text-transform: capitalize;
		float: none;
		box-shadow: none;
	}

	.mm-menu .mm-listitem_selected > a:not(.mm-btn_next),
	.mm-menu .mm-listitem_selected > span {
		background-color: transparent;
		cursor: pointer;
	}

	.mm-menu a.w--current {
		background-color: #012b5d;
		cursor: pointer;
	}

	.mm-menu a:hover {
		background-color: #012b5d;
		cursor: pointer;
	}

	.mm-listitem:after {
		border-bottom-width: 0px;
	}

	.mm-menu .mm-btn_prev:hover,
	.mm-menu .mm-navbar__title:hover,
	.mm-menu .mm-btn_next:hover {
		color: #fff;
		background-color: transparent;
		cursor: pointer;
	}

	.mm-listitem .mm-btn_next:not(.mm-btn_fullwidth) {
		width: 20%;
	}

	.mm-listitem .mm-btn_next:before {
		border-left-width: 1px;
		border-left-color: #fff;
	}

	.mm-menu .mm-btn:after,
	.mm-menu .mm-btn:before,
	.mm-menu .mm-listview .mm-btn_next:after {
		border-color: hsla(0, 0%, 100%, 0.75);
	}

	.mm-btn_next:after,
	.mm-btn_prev:before {
		width: 9px;
		height: 9px;
	}

	.mm-btn_next:after {
		right: 44%;
	}

	.mm-btn_prev:before {
		margin-left: 0;
	}

	.mm-menu .mm-listview .mm-btn_next:hover {
		background-color: #012b5d;
		cursor: pointer;
	}

	.mm-menu .mm-listview .mm-btn_next + a {
		width: 80%;
		margin-right: 20%;
	}

	.mm-navbar {
		border-bottom: 1px solid #fff;
		padding: 0 10px 0 40px;
		height: 61px;
		margin-right: 0;
	}

	.mm-menu .mm-navbar a,
	.mm-menu .mm-navbar > * {
		height: 61px;
		border-bottom: 0 none !important;
	}

	.mm-navbar__title {
		font-size: 1rem;
		line-height: 1.25rem;
		font-weight: 400;
		text-align: left;
		padding-left: 8px !important;
		text-transform: capitalize;
		border-bottom: 0 none;
	}

	.mm-navbar:hover {
		cursor: pointer;
	}

	.mm-navbar:hover .mm-navbar__title {
		color: #fff;
		cursor: pointer;
	}
}

/* breakpoints */
[data-breakpoints] {
	display: none;
}

@media screen and ( max-width: 991px ) {
	[data-breakpoints*="xl"] {
		display: block;
	}
}

@media screen and ( max-width: 767px ) {
	[data-breakpoints*="md,lg"] {
		display: block;
	}
}

@media screen and ( max-width: 479px ) {
	[data-breakpoints*="xs,sm"] {
		display: block;
	}
}

/*inside page*/
/*right sidebar*/
.box.documents .content > table {
	margin-bottom: 15px;
}

.box.documents .content td:first-of-type {
	padding-right: 10px;
	width: 35px;
}

.box .contentbox_item {
	margin-bottom: 15px;
}

/*left sidebar*/
.left-sidebar {
	padding: 2rem 0;
}

/*blockquotes*/
blockquote p:last-of-type {
	margin-bottom: 0;
}

/*horizontal ruler*/
.divider,
.cms_divider,
.cms_item_divider,
.item_divider {
	height: 1px !important;
	margin-top: 1.5em !important;
	margin-bottom: 1.5em !important;
	background-color: #ccc !important;
	border-top: 0 none !important;
	border-bottom: 0 none !important;
}

hr:not(.fc-divider):not(.formBreak) {
	border-color: #ccc;
	border: 0px;
	color: #ccc;
	margin-bottom: 1.5em;
	margin-top: 1.5em;
	border-top: 1px solid;
}

/*messages*/
#session-message-wrapper .success {
	background-color: #adebb0;
	text-align: left;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	color: #333;
	font-size: 1.125rem;
	line-height: 1.5rem;
	font-weight: 400;
}

#session-message-wrapper .error {
	background-color: #f5dadd;
	text-align: left;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	color: #333;
	font-size: 1.125rem;
	line-height: 1.5rem;
	font-weight: 400;
}

#session-message-wrapper .success p,
#session-message-wrapper .success ul li,
#session-message-wrapper .error p,
#session-message-wrapper .error ul li {
	margin-top: 0;
	font-weight: 400;
	font-size: 1.125rem;
	color: #333;
}

.success *:last-child,
.error *:last-child {
	margin-bottom: 0;
}

/*images*/
td img {
	max-width: none;
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
}

/*tables*/
table.styled {
	width: 100%;
	margin: 10px 0 10px 0;
	background-color: white;
	border-spacing: 0px;
}

table.styled th,
table.styled td {
	border-right: 1px solid white;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.625rem 0.625rem;
	text-align: left;
	background-color: #012b5d;
	color: white;
}

table.styled th {
	font-weight: normal;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
	background-color: darkgray;
	color: black;
}

table.styled th a {
	color: inherit !important;
	font-weight: inherit;
	text-decoration: underline;
	border-bottom: 0 none !important;
}

table.styled th a:hover {
	color: inherit;
	font-weight: inherit;
	text-decoration: none;
}

table.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 0.96rem;
	line-height: 1.4rem;
}

table.styled.striped tr:nth-child(even) {
	background: lightgray;
}

table.styled.striped tr:nth-child(odd) {
	background: transparent;
}

@media (max-width: 39.9375em) {
	table.styled.tablesaw {
		border-bottom: 1px solid grey;
		border-left: 1px solid grey;
	}

	table.styled.tablesaw tbody {
		border-top: 1px solid grey;
	}

	table.styled.tablesaw th,
	table.styled.tablesaw td {
		border-right: 1px solid grey;
		border-bottom: 0 none;
	}

	table.styled.tablesaw tr th:last-child,
	table.styled.tablesaw tr td:last-child {
		border-right: 1px solid grey;
	}

	.tablesaw-stack tbody tr {
		border-bottom: 0 none;
	}
}

/*forms*/
.cms_label,
.label,
label {
	font-weight: 400;
}

label {
	display: inline;
	margin-bottom: 0;
}

input[type="text"],
input[type="tel"],
input[type="password"],
input[type="email"],
input[type="search"],
textarea,
select {
	font-size: 0.875rem;
	line-height: 1.42857143;
	padding: 8px 12px;
	border: 1px solid #878787;
	color: #333333;
}

textarea {
	resize: vertical;
}

/*search results*/
.styles > form p input[type="search"] + button[type="submit"] {
	display: block;
	margin-top: 10px;
}

/*jittery background image on IE and Edge*/
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.fixed-image-section {
		background-attachment: scroll, scroll;
	}
}

@supports (-ms-ime-align: auto) {
	.fixed-image-section {
		background-attachment: scroll, scroll;
	}
}

/* Custom landing pages */
.grid-container {
	margin-top: 137px;
}

.grid-container .slider {
	margin-top: 0px;
}

.grid-container .contentbox-container {
	width: 100%;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
}

.grid-container .contentbox-container .content-box {
	margin: 10px 13px;
}


.grid-container .content-box .content-box-image-container {
	margin-bottom: 10px;
}

.grid-container .content-box .content-box-read-more a {
	border-bottom: 1px solid rgba(20, 76, 141, 0.3);
	transition: all 0.2s ease;
	color: #144c8d;
	line-height: 1.5rem;
	text-transform: capitalize;
}

.grid-container .content-box .content-box-read-more a:hover {
	border-bottom-color: rgba(20, 76, 141, 0.8);
	color: #144c8d;
}

.grid-container .light-grey-background {
	padding-top: 3rem;
	padding-bottom: 3rem;
	background-color: rgba(20, 76, 141, 0.06);
}

.grid-container .blue-background {
	background-image: linear-gradient(90deg, #001c3d, #144c8d 50%, #1664c0);
	padding-top: 3rem;
	padding-bottom: 3rem;
}

.grid-container .light-grey-background .contentbox-container,
.grid-container .blue-background .contentbox-container {
	grid-column: 1 / span 12;
}

.grid-container .content-box.shadow-around-box {
	padding: 1rem;
	background-color: rgba(20, 76, 141, 0.05);
	box-shadow: 0 0 5px 2px #e6e6e6;
	text-align: center;
}

.grid-container .light-grey-background .content-box.fade-in {
	padding: 1rem;
	background-color: #fff;
}

.grid-container .blue-background .content-box.fade-in {
	padding: 2rem;
	background-color: #fff;
	text-align: center;
}

.grid-container .blue-background .content-box.fade-in .content-box-title h2 {
	margin-bottom: 1.5rem;
	color: #012b5d;
	font-size: 2rem;
	line-height: 2.5rem;
	font-weight: 600;
}

.grid-container .light-grey-background .content-box.fade-in .content-box-title h2 {
	margin-bottom: 1rem;
	color: #144c8d;
	font-size: 1.5rem;
	line-height: 2rem;
	font-weight: 600;
}

.grid-container .light-grey-background .content-box.fade-in .content-box-image-container,
.grid-container .blue-background .content-box.fade-in .content-box-image-container {
	width: 100%;
	height: 150px;
	margin-bottom: 0.75rem;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.grid-container .light-grey-background .content-box .news-heading {
	margin-bottom: 0px;
}

.grid-container .content-box.list-box {
	margin-left: 0px;
	margin-right: 0px;
}

.grid-container .content-box.list-box ul {
	margin-left: 0px;
	padding-right: 0px;
	padding-left: 10px;
}

.grid-container .content-box.list-box ul li {
	display: block;
	margin: 0px;
	padding: 0px;
	margin-bottom: 1.25rem;
}

.grid-container .content-box.list-box ul li a {
	padding: 0.35rem 2.5rem 0.35rem 1rem;
	border-left: 4px solid #447dc0;
	background-color: #012b5d;
	background-image: url('/20181016082423/assets/images/arrow-circle-medium.svg');
	background-position: 96% 50%;
	background-size: 20px 20px;
	background-repeat: no-repeat;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
	color: #fff;
	font-size: 1.25rem;
	line-height: 2rem;
	font-weight: 400;
	text-decoration: none;
	display: inline-block;
	width: 100%;
}

.grid-container .content-box.list-box ul li a:hover {
	background-color: #144c8d;
}

@media (max-width: 991px) {
	.grid-container .light-grey-background,
	.grid-container .blue-background {
		padding-top: 2.5rem;
		padding-bottom: 2.5rem;
	}

	.grid-container .content-box.list-box ul {
		display: flex;
		-webkit-box-pack: center;
		justify-content: center;
		flex-wrap: wrap;
		width: 100%;
		margin-right: auto;
		margin-left: auto;
		padding-top: 2.25rem;
		padding-bottom: 1rem;
		margin-bottom: 10px;
		padding-left: 0px;
	}

	.grid-container .content-box.list-box ul li {
		width: 100%;
		min-width: 200px;
		margin-right: 0.6rem;
		margin-left: 0.6rem;
	}

	.grid-container .content-box.list-box ul li a {
		padding-right: 2.25rem;
		font-size: 1rem;
	}
}

@media (max-width: 767px) {
	.grid-container .content-box.list-box ul {
		background-color: #cad7e8;
	}

	.grid-container .content-box.list-box ul li {
		width: 30%;
	}
}

@media (max-width: 479px) {
	.grid-container .light-grey-background,
	.grid-container .blue-background {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	.grid-container .content-box.list-box ul {
		background-color: #fff;
	}
}
